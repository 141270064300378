 @import url("https://use.typekit.net/pot7bfm.css");



// EXAMPLE

// @font-face {
//     font-family: 'museo_slab500';
//     src: url('../assets/m/Museo_Slab_500_2-webfont.eot');
//     src: url('../assets/m/Museo_Slab_500_2-webfont.eot?#iefix') format('embedded-opentype'),
//          url('../assets/m/Museo_Slab_500_2-webfont.woff2') format('woff2'),
//          url('../assets/m/Museo_Slab_500_2-webfont.woff') format('woff'),
//          url('../assets/m/Museo_Slab_500_2-webfont.ttf') format('truetype'),
//          url('../assets/m/Museo_Slab_500_2-webfont.svg#museo_slab500') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }



$fmr: "gira-sans", Helvetica, Arial, Sans-Serif;
