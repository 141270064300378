$text: #000000;
$bgred: rgba(123,149,179,0.74);
$red: rgba(123,149,179,1);
// $bgred: rgba(139,35,50,0.74);
// $red: rgba(139,35,50,1);
$white: #ffffff;

$mainColor:#707070;
$secColor: #4F4F50;

@mixin color_hover($baseColor) {
    color: $baseColor;
    &:hover  {
    	color: darken($baseColor, 13%);
    }
}
