
$textpaddingtopbot: 3.5rem;
$textpaddingsides: 3vw;
$textpadding: $textpaddingtopbot $textpaddingsides;


$textpaddingtopbot-sm: 2.5rem;
$textpaddingsides-sm: 2rem;
$textpadding-sm: $textpaddingtopbot-sm $textpaddingsides-sm;

section {
  z-index: 2;
  position: relative;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  &:nth-of-type(even) {
    margin-left: 50%;

    background-color: $white;
    padding: 0 $textpaddingsides;
  //  padding: $textpaddingsides*2 $textpaddingsides;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    > div {
      max-width: 550px;
    }
  }
  &:nth-of-type(odd) {
    width: 100%;
  }
  &:first-of-type {
    position: fixed;
    top: 0;
    left:0;
    width: 50%;
    margin-left: 0;
    background-color: $bgred;
    z-index: 3;
  }
  &:nth-of-type(2) {
    width: 100%;
    position: fixed;
    top: 0;
    left:0;
    margin-left: 0;
    background-image: url("../img/door_2000x1500px.jpg");
    z-index: 1;
  }
  &:nth-of-type(3) {
    //height: calc(100vh - #{$textpaddingtopbot});
  }
  h1 {
    margin-bottom: $textpaddingtopbot;
    word-wrap: break-word;
    word-break: break-all;
  }
  h2 {
    margin-bottom: $textpaddingtopbot/3;
  }
  h2 {
    margin-top: $textpaddingtopbot;
  }
  p {
    position: relative;
    margin-left: $textpaddingtopbot/1.5;
    margin-bottom: $textpaddingtopbot/6;
    padding-top: $textpaddingtopbot/3;
    &:before {
      content:'';
      border-top:1px solid $secColor;
      width: 2rem;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    > img {
      margin-top: $textpaddingtopbot/3;
      width: 50%;

    }
  }

  .sectionText {

    padding: $textpaddingsides*2 0;
    > p {
      &:last-of-type {
      }
    }
  }
}

#taetigkeiten {
  > div > p {
    margin-bottom: $textpaddingtopbot;
  }
  li {
    display: flex;
    align-items: baseline;
    span {
      content:"-";
      height: 1px;
      border: 0;
      border-top: 1px solid #000;
      width: 60px;
      flex: 0 60px;
    }
    p {
      margin-bottom: $textpaddingtopbot/6;
      flex: 1;
      margin-left: $textpaddingtopbot/6;
    }
  }
}

#bgimg-tatig {
  background-image: url("../img/facade__2000x1500px.jpg");
}

#bgimg-immobilienvermarktung {
  background-image: url("../img/concrete_2000x1500px.jpg");
}

#bgimg-person {
  background-image: url("../img/maps_2000x1500px.jpg");
}

#bgimg-martin {
  background-image: url("../img/buero_2000x1600px.jpg");
}


@include bp(mobile){

  section {
    min-height: inherit;
    &:nth-of-type(even) {
      margin-left: 0;
      padding: $textpadding-sm;
    }
    &:nth-of-type(odd) {
      height: 50vh;
    }
    &:first-of-type {
      position: sticky;
      width: 100%;
      margin-left: 0;
      margin-top: 50vh;
      z-index: 2;
    }
    &:nth-of-type(2) {
      height:100vh;
    }
    &:nth-of-type(3) {
      display: none;
    }
    h1, h2,  div > img {
      margin-bottom: $textpaddingtopbot-sm/2;
    }
    h2 {
      margin-top: $textpaddingtopbot-sm/2;
    }
    p {
      margin-left: 0;
      margin-bottom: $textpaddingtopbot-sm/6;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

}

#favicon {
  width: 64px;
  height: 64px;
  background-color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    color: $white;
    letter-spacing: 0;
    margin-left: -0.1rem;
    margin-top: 0.3rem;
    font-family: $fmr;
    font-size: 2.5rem;
  }
}
@include bp(ipadV){
  section {
    height: inherit;
    &:nth-of-type(even) {
      margin-left: 0;
      padding: $textpadding-sm;
    }
    &:nth-of-type(odd) {
      height: 50vh;
    }
    &:first-of-type {
      position: sticky;
      width: 100%;
      margin-left: 0;
      margin-top: 50vh;
      z-index: 2;
    }
    &:nth-of-type(2) {
      height:100vh;
    }
    &:nth-of-type(3) {
      display: none;
    }
  }

}

@include bp(ipadH){

}

@include bp(desktop){

}
